import log from 'loglevel';
import events from '../custom-events';

const Elemental = {
    init: function (options) {
        const logLevel = options.globalObject.environment ? 'debug' : 'warn';

        this.options = options;
        this.name = this.options.name ?? 'Elemental';
        this.componentIndex = 1;
        this.instances = [];
        this.deferredComponentRequests = [];

        log.setLevel(logLevel);
        document.addEventListener('DOMContentLoaded', this.onDOMLoaded.bind(this));
        window.addEventListener('refresh', this.onRefresh.bind(this));

        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.removedNodes.length) {
                    this.purgeRemovedComponents(mutation.removedNodes);
                }
            });
        });

        this.observer.observe(document.body, { subtree: true, childList: true });
    },

    onDOMLoaded: function () {
        this.initComponents();
        this.renderComponents();

        if (this.options.globalObject.listManager) {
            this.options.globalObject.listManager.init();
        }

        if (this.options.globalObject.notificationManager) {
            this.options.globalObject.notificationManager.init();
        }
    },

    onRefresh(event) {
        const parentEl = event.detail?.parentElement;

        this.refresh(parentEl);
    },

    getComponents: function (parentEl = document.body) {
        return parentEl.querySelectorAll(this.options.componentSelector);
    },

    getRenderedComponentIds: function (parentEl = document.body) {
        const allComponents = this.getComponents(parentEl);
        const componentIds = Array.from(allComponents, (component) => component.dataset.elementalId);

        // Filter out components that haven't been rendered yet (no elementalId dataset property)
        return componentIds.filter((id) => typeof id !== 'undefined');
    },

    initComponents: function () {
        const elements = this.getComponents();

        elements.forEach((element) => this.setComponentId(element));
    },

    setComponentId: function (element) {
        if (!element.dataset.elementalId) {
            element.setAttribute('data-elemental-id', `element-${this.componentIndex}`);
            this.componentIndex++;
        }
    },

    renderComponents: function (parentEl = document.body) {
        const startTime = performance.now();
        const elements = this.getComponents(parentEl);

        log.debug(`[${this.name}] Starting component render for parent:`, parentEl);

        elements.forEach((element) => this.renderComponent(element));

        // Check if the parent element is also a component and needs to be rendered
        if (parentEl.dataset.pulseComponent) {
            this.renderComponent(parentEl);
        }

        log.debug(
            `[${this.name}] Rendered ${elements.length} components in ${Math.round(performance.now() - startTime)}ms.`
        );
    },

    renderComponent: function (element) {
        // Add component ID if it doesn't have one (i.e. if it wasn't in the DOM on page load)
        this.setComponentId(element);

        if (element.dataset.pulseComponent && element.dataset.pulseComponent.length) {
            switch (element.dataset.componentLoad) {
                case 'dynamic':
                    this.loadComponent(element.dataset.pulseComponent).then(() => {
                        this.createComponent(element);
                    });

                    break;

                case 'intersection':
                    this.loadComponent(element.dataset.pulseComponent).then(() => {
                        const observer = new IntersectionObserver(
                            (entries, observer) => {
                                entries.forEach((entry) => {
                                    if (entry.isIntersecting) {
                                        this.createComponent(element);
                                        observer.unobserve(entry.target);
                                    }
                                });
                            },
                            {
                                threshold: 0.25,
                            }
                        );

                        observer.observe(element);
                    });

                    break;

                default:
                    this.createComponent(element);
            }
        }
    },

    createComponent: function (element) {
        // Check if element has already been rendered
        const exists = this.getComponentInstance(element.dataset.elementalId);

        if (exists) {
            log.trace(`[${this.name}] Skipping existing component`, exists);

            return;
        }

        const className = this.getComponentClassName(element);

        try {
            const startTime = performance.now();
            const newComponent = new this.Components[className](element, this.options);

            newComponent.type = className;
            newComponent.render();
            newComponent.element.dispatchEvent(new CustomEvent(events.general.rendered));

            log.trace(
                `[Elemental] Rendered component ${className} (${newComponent.id}) in ${Math.round(
                    performance.now() - startTime
                )}ms.`
            );

            this.instances.push(newComponent);

            // Handle any deferred getComponentById requests for this component
            const requests = this.deferredComponentRequests.filter((request) => request.id === newComponent.id);

            if (requests.length) {
                requests.forEach((request) => {
                    request.resolve(newComponent);
                });

                // Reset deferred requests array
                this.deferredComponentRequests = this.deferredComponentRequests.filter(
                    (request) => request !== newComponent.id
                );
            }
        } catch (exception) {
            if (exception instanceof TypeError) {
                if (typeof Sentry !== 'undefined' && this.options.globalObject.environment !== 'local') {
                    Sentry.captureException(exception);
                }
            } else {
                throw exception;
            }

            log.error(`[${this.name}] Error creating component ${className}: ${exception.message}`);
        }
    },

    purgeRemovedComponents: function (removedNodes) {
        let removedIds = [];

        removedNodes.forEach((node) => {
            if (node.querySelectorAll) {
                // Find any Elemental components that were deleted
                const removedComponents = node.querySelectorAll(this.options.componentSelector);
                const ids = Array.from(removedComponents, (element) => element.dataset.elementalId);

                removedIds = [...removedIds, ...ids];
            }
        });

        if (removedIds.length) {
            log.trace(`[${this.name}] Deleting ${removedIds.length} orphaned components.`, removedIds);

            // Delete references to orphaned components
            this.instances.forEach((instance, index) => {
                if (removedIds.includes(instance.id)) {
                    this.instances[index] = null;
                }
            });

            this.instances = this.instances.filter((component) => component !== null);
        }
    },

    getComponentById: function (id) {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject(new Error('No ID defined'));
            }

            const instance = this.getComponentInstance(id);

            if (instance) {
                resolve(instance);
            } else {
                this.deferredComponentRequests.push({
                    id: id,
                    resolve: resolve,
                });
            }
        });
    },

    getComponentInstance: function (id) {
        const instance = this.instances.filter((component) => component.id === id);

        if (instance.length) {
            return instance[0];
        }

        return;
    },

    getComponentClassName: function (element) {
        const className = element.dataset.pulseComponent
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join('');

        return className;
    },

    loadComponent: async function (componentName) {
        return await import(`../../../../components/${componentName}/js/${componentName}.js`);
    },

    refresh: function (parentEl = document.body) {
        log.debug(`[${this.name}] Refreshing components...`);

        this.renderComponents(parentEl);

        if (this.options.globalObject.lazyLoad) {
            this.options.globalObject.lazyLoad.update();
        }
    },

    Components: {},

    BaseComponent: class {
        constructor(element, options) {
            for (const key in options) {
                if (Object.prototype.hasOwnProperty.call(options, key)) {
                    this[key] = options[key];
                }
            }

            this.id = element.dataset.elementalId;
            this.element = element;
            this.bodyEl = document.querySelector('body');
            this.window = options.window;
            this.log = log;
        }

        emitEvent(targetElement, eventName, eventDetails) {
            if (typeof window.CustomEvent !== 'function' || !targetElement) return;

            const options = {
                bubbles: true,
            };

            if (eventDetails) {
                options.detail = eventDetails;
            }

            const event = new CustomEvent(eventName, options);

            targetElement.dispatchEvent(event);
        }
    },
};

export default Elemental;
