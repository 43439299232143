import '../scss/core.scss';

import 'jslib/utils/polyfills';
import Elemental from 'jslib/elemental/elemental';
import AnalyticsManager from 'jslib/analytics-manager/analytics-manager';
import { loadSvgSprite } from 'jslib/utils/utils';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';

import '../../components/form/js/form';
import '../../components/navigation/js/navigation';

loadSvgSprite(PULSE.assets.svg);

PULSE.lazyLoad = new LazyLoad({
    elements_selector: '.lazy-load',
});

PULSE.analyticsManager = new AnalyticsManager({
    globalObject: PULSE,
});

Elemental.init({
    componentSelector: '[data-pulse-component]',
    window: window,
    globalObject: PULSE,
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        // eslint-disable-next-line no-console
        console.log('HMR');
    });
}
