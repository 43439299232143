function loading(text) {
    return `<svg class="icon icon--loading">
        <use xlink:href="#spinner-third"></use>
    </svg>
    <span class="visually-hidden">${text}...</span>`;
}

function compile(data) {
    return loading(data);
}

export default compile;
