function getError(error) {
    return `<li class="errors__item">
        <svg class="icon icon--exclamation-triangle">
            <use xlink:href="#exclamation-triangle"></use>
        </svg>
        <span class="errors__item__text">${error}</span>
    </li>`;
}

function errors(errors) {
    return `<ul class="errors list-unstyled">
        ${errors.map(error => getError(error)).join('')}
    </ul>`;
}

function compile(data) {
    return errors(data.errors);
}

export default compile;
