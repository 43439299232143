import Elemental from 'jslib/elemental/elemental';
import FormValidator from 'jslib/form-validator/form-validator';
import events from 'jslib/custom-events';
import analyticsEvents from 'jslib/data-events';
import { getElementDimensions, getNamespace } from 'jslib/utils/utils';
import { Modal } from 'bootstrap';
import isString from 'lodash/isString';
import delay from 'lodash/delay';
import loadingTemplate from '../templates/loading';
import errorListTemplate from '../templates/error-list';

Elemental.Components.Form = class Form extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                loading: 'loading',
                hidden: 'd-none',
            },
            selectors: {
                formGroup: '.form-group',
                formFieldsWrapper: '.form__fields',
                modalActions: '.modal-body__actions',
                success: '.form__success',
            },
        };

        this.submitButtonEl = this.element.querySelector('button[type=submit]');

        this.ajaxSubmit = this.element.dataset.ajaxSubmit;
        this.clearOnSubmit = this.element.dataset.clearOnSubmit ? JSON.parse(this.element.dataset.clearOnSubmit) : true;
        this.successMessage = this.element.dataset.successText;
        this.successRedirect = this.element.dataset.successRedirect;
        this.successTextOverlay = this.element.dataset.successTextOverlay;
        this.showCloseButton = this.element.dataset.showCloseButton;
        this.closeModalOnSuccess = this.element.dataset.closeModalOnSuccess;
        this.submitButtonText = this.submitButtonEl ? this.submitButtonEl.innerText : 'Submit';
        this.showLoadingState = true;
        this.namespace = getNamespace(this.element);

        if (this.element.dataset.showLoadingState) {
            this.showLoadingState = JSON.parse(this.element.dataset.showLoadingState);
        }

        if (this.element.dataset.showSuccessNotification) {
            this.showSuccessNotification = JSON.parse(this.element.dataset.showSuccessNotification);
        } else {
            this.showSuccessNotification = true;
        }
    }

    setEventListeners() {
        this.element.addEventListener(events.forms.resetForm, this.resetForm.bind(this));
        this.element.addEventListener(events.forms.resetValidation, this.resetValidation.bind(this));
        this.element.addEventListener(events.forms.success, this.onSubmissionFinished.bind(this));
        this.element.addEventListener('submit', this.onSubmit.bind(this));
    }

    onSubmit(event) {
        const isFormValid = this.isFormValid();

        if (isFormValid) {
            const eventData = {};

            if (this.element.dataset.modalParent) {
                eventData.modalId = this.element.dataset.modalParent;
            }

            this.routeFormToDestination(event);
            this.trackEvent(analyticsEvents.form.submitted, eventData);
        } else {
            event.preventDefault();
        }

        this.setSubmitButtonDisabled(isFormValid);
    }

    onFormSubmitCompleted(success, data = {}) {
        if (!success) {
            let errors = data.errors ?? [];

            if (data.error) {
                errors = [data.error];

                if (data.message) {
                    errors.push(data.message);
                }
            }

            if (isString(errors)) {
                errors = [errors];
            }

            // Ensure any old errors are removed before we add new ones
            this.resetValidation();

            this.setSubmitButtonDisabled(false);
            this.setSubmitButtonLoading(false);

            if (errors && errors.length) {
                this.renderFreeformErrors(errors);
                this.emitEvent(this.element, events.forms.error, { data });
            }

            return;
        }

        if (success) {
            const message = this.successMessage || data.message || null;

            if (message && this.showSuccessNotification) {
                this.showSuccessMessage(message);
            }

            this.setSubmitButtonLoading(false);
            this.setSubmitButtonDisabled(false);

            if (this.clearOnSubmit) {
                this.clearFormInputs();
            }

            this.resetValidation();
            this.emitEvent(this.element, events.forms.success, { data });
        }
    }

    onSubmissionFinished() {
        const modalParentEl = document.getElementById(`${this.element.dataset.modalParent}`);

        if (this.successRedirect && this.successRedirect.length) {
            delay(() => {
                if (this.successRedirect === window.location.href) {
                    window.location.reload();
                } else {
                    window.location.href = this.successRedirect;
                }
            }, 2000);
        }

        if (this.closeModalOnSuccess && modalParentEl) {
            Modal.getInstance(modalParentEl).hide();
        }
    }

    isFormValid() {
        return this.validator.validateForm();
    }

    resetValidation() {
        const errorsEl = this.element.querySelector(this.css.selectors.errors);

        if (errorsEl) {
            errorsEl.remove();
        }

        this.validator.removeAllErrors();
    }

    routeFormToDestination(event) {
        if (this.ajaxSubmit) {
            event.preventDefault();

            this.submitForm().then((response) => {
                const success = response.status === 200;

                response.json().then(this.onFormSubmitCompleted.bind(this, success));
            });
        } else {
            this.setSubmitButtonLoading(true);
        }
    }

    setSubmitButtonDisabled(disabled) {
        if (!this.showLoadingState) {
            return;
        }

        if (this.submitButtonEl) {
            this.submitButtonEl.disabled = disabled;
        }
    }

    setSubmitButtonLoading(isLoading) {
        if (!this.showLoadingState) {
            return;
        }

        if (isLoading) {
            this.renderSubmitButtonLoadingState(this.element.dataset.loadingText);
        } else {
            this.renderSubmitButtonDefaultState(this.submitButtonText);
        }
    }

    renderErrorList(event, errors) {
        const errorData = {
            errors: errors,
        };
        const errorHtml = errorListTemplate(errorData);
        const errorHtmlEl = document.createRange().createContextualFragment(errorHtml);
        const errorsEl = this.element.querySelector(this.css.selectors.errors);

        if (errorsEl) {
            errorsEl.replaceWith(errorHtmlEl);
        } else {
            this.element.prepend(errorHtmlEl);
        }
    }

    renderFreeformErrors(errors) {
        const freeformErrors = this.getFreeformFieldErrors(errors);

        this.renderErrorList(null, freeformErrors);
    }

    renderSubmitButtonLoadingState(loadingText) {
        const loadingHtml = loadingTemplate({
            text: loadingText,
        });
        const buttonSize = getElementDimensions(this.submitButtonEl);

        // Fix button dimensions while in loading state
        this.submitButtonEl.style.width = `${buttonSize.width}px`;
        this.submitButtonEl.style.height = `${buttonSize.height}px`;
        this.submitButtonEl.innerHTML = loadingHtml;
        this.submitButtonEl.classList.add(this.css.classes.loading);
    }

    renderSubmitButtonDefaultState(buttonText) {
        this.submitButtonEl.innerHTML = buttonText;
        this.submitButtonEl.style.width = '';
        this.submitButtonEl.style.height = '';
        this.submitButtonEl.classList.remove(this.css.classes.loading);
    }

    getFreeformFieldErrors(errorsObject) {
        const errors = [];

        Object.values(errorsObject).forEach((error) => {
            errors.push(error);
        });

        return errors;
    }

    getFormData() {
        const formData = new FormData(this.element);

        return formData;
    }

    async submitForm() {
        this.setSubmitButtonDisabled(true);
        this.setSubmitButtonLoading(true);

        return await fetch('#', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: this.getFormData(),
        });
    }

    resetForm() {
        this.clearFormInputs();
        this.resetValidation();
        this.setSubmitButtonDisabled(false);
        this.setSubmitButtonLoading(false);
    }

    clearFormInputs() {
        this.element.reset();

        this.element
            .querySelectorAll('select, textarea, input:not([type=hidden])')
            .forEach((formEl) => this.emitEvent(formEl, 'change'));
    }

    showSuccessMessage(message) {
        this.globalObject.notificationManager.notify({
            type: 'success',
            message: message,
        });
    }

    updateCsrfInput() {
        for (const [key, value] of Object.entries(this.globalObject.csrf)) {
            const csrfEl = this.element.querySelector(`input[name=${key}]`);

            if (csrfEl) {
                csrfEl.value = value;
            }
        }
    }

    trackEvent(eventName, eventData = {}) {
        this.emitEvent(this.bodyEl, events.analytics.track, {
            name: eventName,
            data: Object.assign({}, eventData, {
                namespace: this.namespace,
            }),
        });
    }

    init() {
        this.validator = new FormValidator(this.element);
        this.updateCsrfInput();
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
};
