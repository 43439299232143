// import { objectToQueryString } from 'jslib/utils/utils';
import { objectToQueryString } from '../utils/utils';

interface FetchDataOptions {
    url?: string;
    search?: string;
    query?: Record<string, object>;
}

class ApiManager {
    apiUrl: string;

    constructor() {
        this.apiUrl = '/api';
    }

    getQueryStringFromOptions(options: { query?: Record<string, object> }): string {
        if (!options.query) {
            throw new Error('No query option specified.');
        }

        return objectToQueryString(options.query);
    }

    getEndpointUrl(elementType: string, elementId?: string, options?: FetchDataOptions): string {
        let endpointUrl: string = this.apiUrl;

        if (!elementType || !elementType.length) {
            throw new Error('No element type specified.');
        }

        endpointUrl = `${endpointUrl}/${elementType}`;

        if (options?.search && options.search.length) {
            endpointUrl = `${endpointUrl}/search?term=${options.search}`;
        } else if (elementId && parseInt(elementId) > 0) {
            endpointUrl = `${endpointUrl}/${elementId}`;
        }

        if (!options?.search) {
            endpointUrl = `${endpointUrl}.json`;
        }

        if (options?.query) {
            const queryString = this.getQueryStringFromOptions(options);
            endpointUrl = `${endpointUrl}?${queryString}`;
        }

        return endpointUrl;
    }

    async fetchData(elementType: string, elementId?: string, options?: FetchDataOptions): Promise<object> {
        let url: string;

        if (options?.url) {
            url = options.url;
            let qsDelimiter: string = '?';

            if (options.query) {
                const queryString = this.getQueryStringFromOptions(options);

                if (url.includes('?')) {
                    qsDelimiter = '&';
                }

                url = `${url}${qsDelimiter}${queryString}`;
            }
        } else {
            url = this.getEndpointUrl(elementType, elementId, options);
        }

        if (!url) {
            throw new Error('No url specified.');
        }

        const response: Response = await fetch(url, {
            headers: {
                Accept: 'application/json',
            },
            method: 'GET',
        });

        return await response.json();
    }

    async getJwt(endpoint: string): Promise<object> {
        const url: string = `${this.apiUrl}/${endpoint}/token.json`;
        const response: Response = await fetch(url, {
            headers: {
                Accept: 'application/json',
            },
        });

        return await response.json();
    }
}

export default ApiManager;
