import { generateUid, setCookie, getCookie } from 'jslib/utils/utils';
import events from 'jslib/custom-events';
import Bowser from 'bowser';
import log from 'loglevel';

class AnalyticsManager {
    constructor(options) {
        const logLevel = options.globalObject.environment ? 'debug' : 'warn';

        this.options = options;

        this.sessionCookieName = 'paSessionId';
        this.urls = {
            trackEvent: '/index.php?p=actions/pulse-analytics/track',
        };

        this.startTime = performance.now();
        this.setEventListeners();
        log.setLevel(logLevel);

        return AnalyticsManager.instance;
    }

    setEventListeners() {
        window.addEventListener('load', this.onPageLoaded.bind(this));
        document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
        document.body.addEventListener(events.analytics.track, this.track.bind(this));
    }

    onPageLoaded() {
        this.track({
            detail: {
                name: 'pageVisit',
            },
        });

        this.handleUrlTrackingParams();
    }

    onVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this.track({
                detail: {
                    name: 'pageLeave',
                    data: {
                        duration: Math.round((performance.now() - this.startTime) / 1000),
                    },
                },
            });
        }
    }

    getGlobalProperties() {
        const url = document.createElement('a');
        const browser = Bowser.parse(window.navigator.userAgent);
        let pageTitle = this.options.globalObject.page.title;

        if (!pageTitle || !pageTitle.length) {
            pageTitle = document.title.split(' | ')[0];
        }

        const globalProperties = {
            userId: this.getUserId(),
            pageId: this.options.globalObject.page.id ? parseInt(this.options.globalObject.page.id, 10) : null,
            pageTitle: pageTitle,
            pageType: this.options.globalObject.page.type,
            pageUrl: document.location.href,
            loggedIn: this.options.globalObject.user.loggedIn,
            referrer: document.referrer,
            userAgent: window.navigator.userAgent,
            browser: browser.browser.name,
            browserVersion: browser.browser.version,
            os: browser.os.name,
            osVersion: browser.os.version,
            platform: browser.platform.type,
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight,
            timestamp: this.options.globalObject.timestamp,
        };

        url.href = document.referrer;
        globalProperties.referringDomain = url.hostname;

        return globalProperties;
    }

    getUserId() {
        return this.options?.globalObject?.user?.id;
    }

    getSessionIdFromCookie() {
        return getCookie(this.sessionCookieName);
    }

    setSessionIdInCookie(sessionId) {
        // Create a cookie with a max-age of 15 minutes
        setCookie(this.sessionCookieName, sessionId, 900);
    }

    getSessionId() {
        let sessionId = this.getSessionIdFromCookie();

        if (!sessionId || !sessionId.length) {
            sessionId = generateUid(32);
            this.setSessionIdInCookie(sessionId);
        }

        return sessionId;
    }

    handleUrlTrackingParams() {
        // eslint-disable-next-line no-useless-escape
        const search = window.location.search.replace(/[\[\]]+/g, '');
        const params = new URLSearchParams(search);
        const track = params.get('trk');
        const campaign = params.get('cmp');
        const source = params.get('src');

        if (track === 'true' && campaign && source && campaign.length && source.length) {
            this.track({
                detail: {
                    name: `${campaign}_${source}_click`,
                },
            });
        }
    }

    track(event) {
        const eventName = event.detail.name;
        const formData = new FormData();
        const globalData = this.getGlobalProperties();
        const sendBeacon = navigator.sendBeacon && navigator.sendBeacon.bind(navigator);
        let eventData = event.detail.data || {};

        eventData = Object.assign({}, eventData, globalData, { session_id: this.getSessionId() });

        formData.append('eventName', eventName);
        formData.append('eventData', JSON.stringify(eventData));

        for (const [key, value] of Object.entries(this.options.globalObject.csrf)) {
            formData.append(key, value);
        }

        if (eventName) {
            log.info(`[Analytics] ${eventName}`, Object.assign({}, eventData, globalData));

            if (this.options.globalObject.environment !== 'local') {
                // sendBeacon can throw errors, so use it with `navigator` super-bound
                // https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
                try {
                    formData.append('requestType', 'beacon');
                    sendBeacon(this.urls.trackEvent, formData);
                } catch (error) {
                    fetch(this.urls.trackEvent, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                        },
                        body: formData,
                    });
                }
            }
        } else {
            throw new Error('Analytics event name not defined.');
        }
    }
}

export default AnalyticsManager;
